<script setup lang="ts">
  import { useScreenSize } from '~/shared/lib'
  import { SignoutPopup } from '~/shared/ui/components'
  import { SignoutIcon, InfoIcon, ChevronIcon } from '~/shared/ui/svg'

  import { useProfile, UserAvatar } from '~/entities/profile'

  const { profile } = storeToRefs(useProfile())
  const userName = computed(() => profile.value?.firstName || '')
  const showSignoutPopup = ref(false)
  const toggleSignoutPopup = () => {
    showSignoutPopup.value = !showSignoutPopup.value
  }
  const menuIsActive = ref(false)
  const { isMobile } = storeToRefs(useScreenSize())
  const userAvatarSize = eagerComputed(() => (isMobile.value ? '40px' : '35px'))
  const userAvatar = computed(() => profile.value?.avatar)
</script>

<template>
  <div class="user-menu__container">
    <SignoutPopup v-model="showSignoutPopup" />
    <UserAvatar
      :src="userAvatar"
      :size="userAvatarSize"
    />
    <span
      v-if="!isMobile"
      class="user-menu__name"
    >
      {{ userName }}
    </span>
    <ChevronIcon
      v-if="!isMobile"
      :class="['user-menu__arrow', { 'user-menu__arrow_active': menuIsActive }]"
    />
    <q-menu
      v-if="!isMobile"
      :offset="[0, 14]"
      class="user-menu__dropdown"
      fit
      transition-show="slide-down"
      transition-hide="slide-up"
      @before-show="menuIsActive = true"
      @before-hide="menuIsActive = false"
    >
      <q-list>
        <q-item
          v-close-popup
          clickable
          class="user-menu__item"
          @click="navigateTo('/info')"
        >
          <InfoIcon />{{ $t('header_dropdown-info') }}
        </q-item>
        <q-item
          v-close-popup
          clickable
          class="user-menu__item"
          @click="toggleSignoutPopup"
        >
          <SignoutIcon />{{ $t('base_signout') }}
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<style scoped lang="scss">
  .user-menu {
    &__container {
      @include flex-container(row, flex-start, center);
      background-color: $purple-light;
      gap: 14px;
      border-radius: 20px;
      height: 35px;
      width: 237px;
      position: relative;
      cursor: pointer;
    }
    &__avatar {
      margin-left: -4px;
    }
    &__name {
      @include font-montserrat-500(14px, 17px, $white-1);
    }
    &:global(.user-menu__dropdown) {
      border-radius: 0 0 20px 20px;
      background-color: $purple-light;
      color: $white-1;
      z-index: 3;
    }
    &__item {
      @include flex-container(row, flex-start, center);
      @include font-montserrat-500(14px, 17px, $white-1);
      gap: 8px;
    }
    &__arrow {
      transition: all 0.2s ease;
      position: absolute;
      right: 14px;
      &_active {
        transform: rotate(180deg);
      }
    }
  }
  @include mobile {
    .user-menu {
      &__container {
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }
</style>
