<script setup lang="ts">
  import { useScreenSize } from '~/shared/lib'

  import { DefaultLayoutHeader, DefaultLayoutSidebar } from '~/widgets/layout'

  const { fromDesktopMedium, isMobile } = storeToRefs(useScreenSize())

  const showSidebar = ref(true)
  const setOverflow = (value: boolean) => {
    document.body.style.overflow = value ? 'hidden' : 'visible'
    document.body.style.height = value ? '100vh' : 'auto'
  }
  const handleToggleSidebar = () => {
    if (!fromDesktopMedium.value) {
      showSidebar.value = !showSidebar.value
    }

    if (isMobile.value) {
      setOverflow(showSidebar.value)
    }
  }
  watch(
    () => fromDesktopMedium.value,
    (value) => {
      showSidebar.value = value
    },
    {
      immediate: true
    }
  )
  watchEffect(() => {
    if (!isMobile.value) {
      setOverflow(false)
    }
  })
</script>

<template>
  <div class="default-layout__container">
    <DefaultLayoutHeader
      v-model="showSidebar"
      @toggle-burger="handleToggleSidebar"
    />
    <div class="default-layout__page-wrapper">
      <DefaultLayoutSidebar
        v-if="showSidebar"
        class="default-layout__sidebar"
        @close-sidebar="handleToggleSidebar"
      />
      <div class="default-layout__page-container">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .default-layout {
    &__container {
      box-sizing: border-box;
      background-color: $grey;
      @include flex-container(column, flex-start, center);
    }
    &__page-wrapper {
      width: 100%;
      max-width: 1280px;
      @include flex-container(row, center, stretch);
      gap: 33px;
      padding-left: 34px;
    }
    &__page-container {
      padding: 14px 40px;
      z-index: 2;
      width: 1053px;
      background-color: $white;
      min-height: calc(100vh - 64px); //fallback for old browsers
      min-height: calc(100svh - 64px);
      flex-shrink: 0;
    }
  }
  @include desktop-medium {
    .default-layout {
      &__page-container {
        width: 100%;
      }
      &__page-wrapper {
        padding-left: 0;
      }
    }
  }
  @include mobile {
    .default-layout {
      &__page-container {
        @include flex-container(column, flex-start, center);
        padding: 14px 0;
      }
    }
  }
</style>
