<script setup lang="ts">
  // eslint-disable-next-line boundaries/element-types
  import { useAuth } from '~/features/auth'

  import BaseDialog from './BaseDialog.vue'

  const { signOut } = useAuth()
</script>

<template>
  <BaseDialog
    confirm-label="base_yes"
    cancel-label="base_no"
    message="signout_popup_question"
    @confirm="signOut"
  />
</template>

<style scoped lang="scss"></style>
