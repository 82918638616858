<script setup lang="ts">
  import { SearchIcon } from '~/shared/ui/svg'

  const emits = defineEmits<{
    (event: 'search', query: string): void
  }>()

  const input = ref('')
  const handleSearch = () => {
    emits('search', input.value)
  }
</script>

<template>
  <q-input
    v-model="input"
    rounded
    outlined
    :placeholder="$t('header_search')"
    class="header__search-panel"
    input-class="header__search-panel-input"
    hide-bottom-space
    color="white"
    dense
    @keydown.enter="handleSearch()"
  >
    <template #prepend>
      <search-icon class="header__search-icon"> </search-icon>
    </template>
  </q-input>
</template>

<style scoped lang="scss">
  .header__search-panel {
    &:deep() {
      .header__search-panel-input {
        @include placeholder-color($white-1);
        @include font-montserrat-500(14px, 17px, $white-1);
      }
      .header__search-icon {
        color: $white-1;
      }
      .q-field__control {
        border-radius: 20px;
        &:before,
        &:hover:before,
        &:after {
          border-width: 1px;
          border-color: $purple-light;
        }
      }
    }
  }
  @include mobile {
    .header__search-panel {
      &:deep() {
        .header__search-panel-input {
          @include placeholder-color($purple-light);
          @include font-montserrat-500(14px, 17px, $purple-light);
        }
        .header__search-icon {
          color: $purple-light;
        }
      }
    }
  }
</style>
