<script setup lang="ts">
  const props = defineProps<{
    modelValue: boolean
  }>()
  const emits = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
  }>()

  const toggleMenu = () => {
    emits('update:modelValue', !props.modelValue)
  }
</script>

<template>
  <div
    class="burger-menu"
    @click="toggleMenu"
  >
    <div
      v-for="item in 3"
      :key="item"
      :class="['burger-menu__bar', { 'burger-menu__bar--active': modelValue }]"
    />
  </div>
</template>

<style lang="scss">
  .burger-menu {
    @include flex-container(column, space-between, flex-start);
    background: none;
    border: none;
    position: relative;
    width: 24px;
    height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    flex-shrink: 0;

    &:focus {
      outline: none;
    }

    &__bar {
      background-color: $white;
      height: 3px;
      border-radius: 2px;
      width: 100%;
      transition: all 0.3s ease-in-out;

      &--active:nth-child(1) {
        transform: rotate(45deg) translate(6px, 3px);
      }

      &--active:nth-child(2) {
        opacity: 0;
      }

      &--active:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -4px);
      }
    }
  }
</style>
