<script setup lang="ts">
  import { useScreenSize } from '~/shared/lib'
  import { SignoutPopup } from '~/shared/ui/components'
  const props = defineProps<{
    to: string
  }>()
  const emits = defineEmits<{
    (e: 'handle-navigate'): void
  }>()
  const route = useRoute()
  const showSignoutPopup = ref(false)

  const toggleSignoutPopup = () => {
    showSignoutPopup.value = !showSignoutPopup.value
  }
  const isActiveItem = computed(() => {
    if (props.to !== '/') {
      return route.path.startsWith(props.to)
    }
    return route.path === props.to
  })
  const { isMobile } = storeToRefs(useScreenSize())
  const handleClick = () => {
    if (props.to === '/signout') {
      toggleSignoutPopup()
    } else {
      if (isMobile.value) {
        document.body.style.overflow = 'visible'
        document.body.style.height = 'auto'
      }
      emits('handle-navigate')
      navigateTo(props.to)
    }
  }
</script>

<template>
  <div
    :to="to"
    :class="['sidebar-item__container', { active: isActiveItem }]"
    @click="handleClick()"
  >
    <SignoutPopup
      v-if="props.to === '/signout'"
      v-model="showSignoutPopup"
    />
    <div class="sidebar-item__icon"><slot name="icon" /></div>
    <div class="sidebar-item__name"><slot name="name" /></div>
  </div>
</template>

<style scoped lang="scss">
  .sidebar-item {
    &__container {
      @include flex-container(row, flex-start, center);
      gap: 8px;
      color: $black;
      cursor: pointer;
      border-radius: 20px;
      max-width: 160px;
      width: 100%;

      padding: 9px 2px 9px 13px;
      @include font-montserrat-500(14px, 17px);
      letter-spacing: -0.2px;
      text-decoration: none;
      transition: all 0.2s ease;
      z-index: 2;
      &:hover,
      &.active {
        background-color: #fff;
        & .sidebar-item__icon {
          color: $purple-light;
        }
      }
    }
    &__icon {
      transition: all 0.2s ease;
      margin-top: 2px;
    }
  }
  @include mobile {
    .sidebar-item {
      &__container {
        max-width: unset;
      }
    }
  }
</style>
